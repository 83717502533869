/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import { Image } from "primereact/image";
import { Button } from "primereact/button";
import { approvalStatus, getBgcolor, dateFormatter, getApprovalStatusKey } from "../utils";
import {
  ApproveDeathRegistrationsApi,
  QueryDeathRegistrationsApi,
  getSingleDeathRegs,
} from "../redux/apiCalls";
import ApprovalActions from "../components/approvelActions";
import RecordDetails from "../components/recordDetails";
import StatusStrip from "../components/statusStrip";

const SingleApplication2 = () => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [approveSuccess, setApproveSuccess] = useState(false);
  const [records, setRecords] = useState([]);
  const [queriedReason, setQueriedReason] = useState([]);
  const [color, setColor] = useState("");

  const getSingleRecord = async () => {
    setLoading(true);
    try {
      const response = await getSingleDeathRegs(id);
      setLoading(false);
      setRecords(response.data);
      setQueriedReason(response.queried);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getSingleRecord();
  }, [id, approveSuccess]);

  useEffect(() => {
    const color = getBgcolor(+records?.approved_status);
    setColor(color);
  }, [records, approveSuccess]);

  if (loading) {
    return (
      <div className="flex justify-content-center align-items-center">
        <ProgressSpinner style={{ width: "3rem", height: "3rem" }} />
      </div>
    );
  }

  return (
    <div>
      <div>
        <Button
          label="Go back"
          icon="pi pi-arrow-left"
          className="p-button-sm my-2"
          onClick={goBack}
        />
      </div>
      <div className="flex flex-column gap-5">
        <StatusStrip
          color={color}
          status={getApprovalStatusKey(+records.approved_status)}
        />
        {+records?.approved_status === approvalStatus.QUERIED && (
          <RecordDetails
            header="Reason for Querying"
            records={queriedReason}
            fieldsToDisplay={["reason", "time_queried"]}
            fieldProcessors={{
              time_queried: (records) => {
                return dateFormatter(records?.created_at);
              },
              surname: (records) => `${records?.childData?.surname ?? "----"}`,
            }}
          />
        )}

        <RecordDetails
          records={records}
          fieldsToDisplay={[
            "death_certificate_number",
            "place_of_death",
            "cause_of_death",
          ]}
          fieldProcessors={{
            surname: (records) => `${records?.informantData?.surname ?? ""}`,
            place_of_death: (records) =>
              `${
                records?.deceased_place_of_deathData?.BirthPlace_Desc ?? "----"
              }`,
            cause_of_death: (records) =>
              `${records?.deceased_cause_of_deathData?.Description ?? "----"}`,
          }}
        />

        <RecordDetails
          type="Deceased"
          records={records}
          fieldsToDisplay={[
            "surname",
            "other_names",
            "state_of_origin",
            "lga_of_origin",
            "marital_status",
            "Nationality",
            "Literacy",
            "Level_of_Education",
          ]}
          fieldProcessors={{
            surname: (records) => `${records?.deceasedData?.surname}`,
            other_names: (records) =>
              `${records?.deceasedData?.firstname ?? ""} ${
                records?.deceasedData?.middle_name ?? ""
              }`,
            state_of_origin: (records) =>
              `${
                records?.deceasedData?.origin_stateData?.State_Name ?? "----"
              }`,
            lga_of_origin: (records) =>
              `${records?.deceasedData?.origin_lgaData?.LGA_Name ?? "----"}`,
            marital_status: (records) =>
              `${
                records?.deceasedData?.current_marital_statusData
                  ?.Status_Desc ?? "----"
              }`,
            Nationality: (records) =>
              `${
                records?.deceasedData?.current_residence_countryData
                  ?.Country_Nationality_ ?? "----"
              }`,
            Literacy: (records) =>
              `${
                records?.deceasedData?.current_literacy_levelData?.Literacy ??
                "----"
              }`,
            Level_of_Education: (records) =>
              `${
                records?.deceasedData?.current_education_levelData
                  ?.Description ?? "----"
              }`,
          }}
        />

        <RecordDetails
          type="Informant"
          records={records}
          fieldsToDisplay={[
            "Relationship",
            "surname",
            "other_names",
            "country_of_residence",
            "state_of_residence",
            "lga_of_residence",
            "Phone_Number",
            "NIN",
            "Street_Address",
          ]}
          fieldProcessors={{
            Relationship: (records) =>
              `${records?.informant_relationship_with_deceasedData?.Description}`,
            surname: (records) =>
              `${records?.informantData?.surname ?? "----"}`,
            other_names: (records) =>
              `${records?.informantData?.firstname ?? " "} ${
                records?.informantData?.middle_name ?? " "
              }`,
            country_of_residence: (records) =>
              `${
                records?.informantData?.current_residence_countryData
                  ?.Country_Name ?? "----"
              }`,
            state_of_residence: (records) =>
              `${
                records?.informantData?.current_residence_stateData
                  ?.State_Name ?? "----"
              }`,
            lga_of_residence: (records) =>
              `${
                records?.informantData?.current_residence_lgaData?.LGA_Name ??
                "----"
              }`,
            Phone_Number: (records) =>
              `${records?.informantData?.current_phone_number ?? "----"}`,
            NIN: (records) => `${records?.informantData?.nin ?? "----"}`,
            Street_Address: (records) =>
              `${records?.informantData?.current_address ?? "----"}`,
          }}
        />

        <article className="shadow-lg bg-white  text-sm px-4 py-2 box-shadow-css">
          <h2 className="text-sm text-black-alpha-90 font-bold">
            <span className="border-bottom-2 border-blue-600">Uploaded </span>{" "}
            Documents
          </h2>
          <section
            className="flex flex-wrap gap-3 w-full"
            style={{ wordBreak: "break-word" }}
          >
            {records?.SupportingDocument?.length > 0 ? (
              records.SupportingDocument?.filter(
                (doc) => doc.file !== null
              ).map((filteredDoc) => (
                <div
                  style={{ maxWidth: "30%" }}
                  className="flex flex-wrap gap-3 w-full"
                  key={filteredDoc.type}
                >
                  {filteredDoc.mimeType ? (
                    <>
                      {filteredDoc.mimeType === "application/pdf" ? (
                        <div className="card shadow-lg h-[200px] flex items-center px-2 justify-center rounded-md overflow-hidden">
                          <div className="gap-3 block ">
                            <a
                              className="text-xs"
                              href={filteredDoc.file}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <i className="pi pi-file-pdf h-20 text-center w-full w-2 text-red-700" />
                              {filteredDoc.type?.replace("-", " ")}
                            </a>
                          </div>
                        </div>
                      ) : (
                        <div className="shadow-lg w-full rounded-md overflow-hidden">
                          <p className="w-full capitalize">
                            <span className="font-bold">Document Type: </span>{" "}
                            {filteredDoc.type?.replace("-", " ")}
                          </p>
                          <Image
                            src={filteredDoc.file}
                            zoomSrc={filteredDoc.file}
                            alt={filteredDoc.type}
                            preview
                            width="100%"
                            height="300px"
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <div
                        style={{ maxWidth: "30%" }}
                        className="shadow-lg rounded-md overflow-hidden"
                      >
                        <p className="w-full capitalize">
                          <span className="font-bold">Document Type: </span>{" "}
                          {filteredDoc.type?.replace("-", " ")}
                        </p>
                        <Image
                          src={filteredDoc.file}
                          zoomSrc={filteredDoc.file}
                          alt={filteredDoc.type}
                          preview
                          width="100%"
                        />
                      </div>
                    </>
                  )}
                </div>
              ))
            ) : (
              <p>No supporting documents available.</p>
            )}
          </section>
        </article>
      </div>

      <ApprovalActions
        id={id}
        records={records}
        setApproveSuccess={setApproveSuccess}
        approveSuccess={approveSuccess}
        approveApi={ApproveDeathRegistrationsApi}
        queryApi={QueryDeathRegistrationsApi}
        ApprovalStatus={+records?.approved_status}
        approvalStatusType={approvalStatus}
      />
    </div>
  );
};

export default SingleApplication2;
